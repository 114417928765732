
import Web3 from "web3";
import React, { useEffect, useState } from "react";
import Web3Modal from "web3modal";
import navLinks from "./Navbar";
import ReactLoading from 'react-loading';
// import nfts from "./Nfts";
import logo from "./logo.svg";
import axios from 'axios';
// import WalletConnectProvider from "@walletconnect/web3-provider";
// import {ethers} from "ethers";
// import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
// import WalletLink from 'walletlink'
import WalletConnectProvider from "@walletconnect/web3-provider";
import { nft_contract_address, chain_name, nft_contract_abi,chain_id, moralis_api_key, images_ipfs_link, staking_contract_abi, staking_contract_address} from './config';
import WalletConnect from "walletconnect";

const Main = () => {

  const [stateOne, setStateOne] = useState(true);
  const [seeCollection, setSeeCollection] = useState(false);
  const [isWalletConnected,setisWalletConnected]= useState(false);
  const [connectedAddress, setConnectedAddress] = useState("");
  const [web3Global, setWeb3Global] = useState();
  const [nftListinWallet, setNftListInWallet] = useState([])
  const [totalWOCStaked, setTotalWOCStaked] = useState(0);
  const [tokensEarned, setTokenEarned] = useState(0);
  const [totalStakedNftsByUser, settotalStakedNftsByUser] = useState(0)
  const [total_UnStakedNftsByUser, settotal_UnStakedNftsByUser] = useState(0)
  const [rewardsPerDay, setRewardsPerDay] = useState(0);
  const [shortenAddress, setShortenAddress] = useState("0x57...fez");
  const [IdsofUnstakedNfts, setIdsofUnstakedNfts] = useState([]);
  const [Idsof_StakedNfts, setIdsof_StakedNfts] = useState([]);
  const [isApproved, setIsApproved] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  
  useEffect(() => {
    setisLoading(false);
    fetch_data_before();
    const checkConnection = async () => {

      // Check if browser is running Metamask
      let web3;
      if (window.ethereum) {
          web3 = new Web3(window.ethereum);
      } else if (window.web3) {
          web3 = new Web3(window.web3.currentProvider);
      };

      // Check if User is already connected by retrieving the accounts
      web3.eth.getAccounts()
          .then(async (addr) => {
              // Set User account into state
              connect_wallet();
          });
  };
  checkConnection();
  }, []);
  async function connect_wallet(){

    if(Web3.givenProvider){
      const providerOptions = {
        /* See Provider Options Section */
      };
    //   const providerOptions = {
    //     walletconnect: {
    //       package: WalletConnectProvider, // required
    //       options: {
    //         infuraId: "460f40a260564ac4a4f4b3fffb032dad", // required
    //       },
    //     },
    //     'custom-walletlink': {
    //       display: {
    //         logo: 'https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0',
    //         name: 'Coinbase',
    //         description: 'Connect to Coinbase Wallet (not Coinbase App)',
    //       },
    //       options: {
    //         appName: 'Coinbase', // Your app name
    //         networkUrl: `https://mainnet.infura.io/v3/3a7c52d946cc4adcb4f1e8b48f5a4601`,
    //         chainId: 1,
    //       },
    //       package: WalletLink,
    //       connector: async (_, options) => {
    //         const { appName, networkUrl, chainId } = options
    //         const walletLink = new WalletLink({
    //           appName,
    //         })
    //         const provider = walletLink.makeWeb3Provider(networkUrl, chainId)
    //         await provider.enable()
    //         return provider
    //       },
    //     },
      
             
    
    
    // };
      const web3Modal = new Web3Modal({
        cacheProvider: true, // optional
        providerOptions // required
      });
      
      const provider = await web3Modal.connect();
      provider.on("accountsChanged", (accounts) => {
        console.log(accounts);
        window.location.reload();
      });
      
      // Subscribe to chainId change
      provider.on("chainChanged", (chainId) => {
        console.log(chainId);
        window.location.reload();
      });
      
      // Subscribe to provider connection
      provider.on("connect", (info) => {
        console.log(info);
        window.location.reload();
      });
      
      // Subscribe to provider disconnection
      provider.on("disconnect", (error) => {
        console.log(error);
        window.location.reload();
      });
      
      const web3 = new Web3(provider);
      const addresses = await web3.eth.getAccounts()
       let address = addresses[0];
       //address = "0xE436c5c45D5B8b9DeDF37A59d9BE8AE7864b26CB";
       setWeb3Global(web3);
      setConnectedAddress(address);
      setShortenAddress(address.slice(0, 4) + "..." + address.slice(address.length - 3, address.length))
      //alert("Connected")
      web3.eth.net.getId().then((result) => { 
    
      console.log("Network id: "+result)
      if(result !== parseInt(chain_id)){ //result !== 1
          //alert("Wrong Network Selected. Select Goerli Test Network and Refresh Page");
          try{
            window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: web3.utils.toHex(chain_id) }]
            });
          }catch(e){
            alert("Error in Switching Chain, Make Sure you have added cronos chain in your web3 wallet.");
            window.location.reload();
          }

        }else{
        //   const contract = new web3.eth.Contract(contract_abi, contract_address);
 
        setisWalletConnected(true);
        setStateOne(!stateOne);
        fetch_Data_After_Wallet_Connect(address);
        setShowPopup(false);
            // get_nfts(address);
            // get_data(web3, address)
        }
      })

    }else{
      alert("Web3 Not Found. Try refreshing if you have metamask installed.");
    }
    
  }

  async function connect_wallet_WalletConnect(){
    const provider = new WalletConnectProvider({
      rpc: {
        25: "https://evm-cronos.crypto.org/",
        5777: "http://127.0.0.1:7545"
      },
    });
    
    //  Enable session (triggers QR Code modal)
    await provider.enable();
    
   
      const providerOptions = {
        /* See Provider Options Section */
      };
    //   const providerOptions = {
    //     walletconnect: {
    //       package: WalletConnectProvider, // required
    //       options: {
    //         infuraId: "460f40a260564ac4a4f4b3fffb032dad", // required
    //       },
    //     },
    //     'custom-walletlink': {
    //       display: {
    //         logo: 'https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0',
    //         name: 'Coinbase',
    //         description: 'Connect to Coinbase Wallet (not Coinbase App)',
    //       },
    //       options: {
    //         appName: 'Coinbase', // Your app name
    //         networkUrl: `https://mainnet.infura.io/v3/3a7c52d946cc4adcb4f1e8b48f5a4601`,
    //         chainId: 1,
    //       },
    //       package: WalletLink,
    //       connector: async (_, options) => {
    //         const { appName, networkUrl, chainId } = options
    //         const walletLink = new WalletLink({
    //           appName,
    //         })
    //         const provider = walletLink.makeWeb3Provider(networkUrl, chainId)
    //         await provider.enable()
    //         return provider
    //       },
    //     },
      
             
    
    
    // };
      // const web3Modal = new Web3Modal({
      //   cacheProvider: true, // optional
      //   providerOptions // required
      // });
      
      // const provider = await Web3Modal.connectTo(WalletConnect)
      provider.on("accountsChanged", (accounts) => {
        console.log(accounts);
        window.location.reload();
      });
      
      // Subscribe to chainId change
      provider.on("chainChanged", (chainId) => {
        console.log(chainId);
        window.location.reload();
      });
      
      // Subscribe to provider connection
      provider.on("connect", (info) => {
        console.log(info);
        window.location.reload();
      });
      
      // Subscribe to provider disconnection
      provider.on("disconnect", (error) => {
        console.log(error);
        window.location.reload();
      });

      //await web3Modal.toggleModal();
      const web3 = new Web3(provider);
      const addresses = await web3.eth.getAccounts()
       let address = addresses[0];
       setWeb3Global(web3);
      setConnectedAddress(address);
      setShortenAddress(address.slice(0, 4) + "..." + address.slice(address.length - 3, address.length))
      //alert("Connected")
      web3.eth.net.getId().then((result) => { 
    
      console.log("Network id: "+result)
      if(result !== parseInt(chain_id)){ //result !== 1
          alert("Wrong Network Selected. Select Cronos Network and Connect Again");
          provider.disconnect();
          return;
          try{
            window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: web3.utils.toHex(chain_id) }]
            });
          }catch(e){
            alert("Error in Switching Chain, Make Sure you have added cronos chain in your web3 wallet.");
            window.location.reload();
          }

        }else{
        //   const contract = new web3.eth.Contract(contract_abi, contract_address);
 
        setisWalletConnected(true);
        setStateOne(!stateOne);
        fetch_Data_After_Wallet_Connect(address);
        setShowPopup(false);
            // get_nfts(address);
            // get_data(web3, address)
        }
      })

 
    
  }
  async function fetch_Data_After_Wallet_Connect(connectedAddress){
    setisLoading(true);
    const providerOptions = {
      /* See Provider Options Section */
    };
    
    const web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions // required
    });
    
    const provider = await web3Modal.connect();
    const web3Global = new Web3(provider);
    
    const staking_contract = new web3Global.eth.Contract(staking_contract_abi, staking_contract_address);

    staking_contract.methods.viewRewards(connectedAddress).call((err,result) => {
      console.log("error: "+err);
      if(result != null){
        setTokenEarned(parseFloat(web3Global.utils.fromWei(result , 'ether')).toFixed(4));
      }
    });
    staking_contract.methods.totalNftStaked().call((err,result) => {
      console.log("error: "+err);
      if(result != null){
        setTotalWOCStaked(parseInt(result))
      }
    });
    staking_contract.methods.numberTokensStaked(connectedAddress).call((err,result) => {
      console.log("error: "+err);
      if(result != null){
        settotalStakedNftsByUser(parseInt(result))
      }
    });
    staking_contract.methods.rewardsPerDay(connectedAddress).call((err,result) => {
      console.log("error: "+err);
      if(result != null){
        //console.log("rewardsPerDay: "+result , "in Ether: "+web3Global.utils.fromWei(result , 'ether'))
        setRewardsPerDay(web3Global.utils.fromWei(result , 'ether'))
      }
    });
    const nft_contract = new web3Global.eth.Contract(nft_contract_abi, nft_contract_address);
    nft_contract.methods.isApprovedForAll(connectedAddress , staking_contract_address).call((err,result) => {
      console.log("error: "+err);
      if(result != null){
       
        if(result.toString() == "true"){
          setIsApproved(true)
        }else{
          setIsApproved(false)
        }
        
      }
      console.log("isApprovedForAll: "+result)
    });
    let temp_all_data = [];
    await staking_contract.methods.getNftsIdsbyAddress(connectedAddress).call((err,result) => {
      console.log("error: "+err);
      if(result != null){
        
        let temp_IdsofUnstakedNfts = [];
        console.log("Unskaed Nft Ids: "+result);
        setIdsofUnstakedNfts(result);
        settotal_UnStakedNftsByUser(result.length)
        for(let i=0;i<result.length;i++){
          let single_data = result[i];
          let temp_img = images_ipfs_link + single_data + ".png";
          if(parseInt(single_data) == 0){
            temp_img = "https://gold-historical-meerkat-310.mypinata.cloud/ipfs/Qmcpayb9i4oggGD3QFHon5F22DgBRmGLsNo4Z3DMkcq966";
          }
          if(parseInt(single_data) == 3530){
            temp_img = "https://gold-historical-meerkat-310.mypinata.cloud/ipfs/QmZHHj3BxDMqveveYXA87FsfXwffM4jEbga92H5eYWGNMY";
          }
          let resulting_json = {
            img: temp_img,
            number: parseInt(single_data),
            staked: false,
            price: '10$FEL per day'
          };
          temp_all_data.push(resulting_json )
          temp_IdsofUnstakedNfts.push(parseInt(single_data));
          console.log("resulting_json: "+resulting_json.img)
        }

        // console.log("temp_all_data: "+temp_all_data);
        // setNftListInWallet(temp_all_data)
        // if(temp_all_data.length > 0){
        //   setSeeCollection(!seeCollection)
        // }
      }
    });
    await staking_contract.methods.getUserstakedNftIds(connectedAddress).call((err,result) => {
      console.log("error: "+err);
      if(result != null){
        let temp_Idsof_stakedNfts = [];
        console.log("User Staked Ids: "+result)
        for(let i=0;i<result.length;i++){
          let single_data = result[i];
          let temp_img = images_ipfs_link + single_data + ".png";
          if(parseInt(single_data) == 0){
            temp_img = "https://gold-historical-meerkat-310.mypinata.cloud/ipfs/Qmcpayb9i4oggGD3QFHon5F22DgBRmGLsNo4Z3DMkcq966";
          }
          if(parseInt(single_data) == 3530){
            temp_img = "https://gold-historical-meerkat-310.mypinata.cloud/ipfs/QmZHHj3BxDMqveveYXA87FsfXwffM4jEbga92H5eYWGNMY";
          }
          
          let resulting_json = {
            img: temp_img,
            number: parseInt(single_data),
            staked: true
          };
          temp_all_data.push(resulting_json )
          temp_Idsof_stakedNfts.push(parseInt(single_data));
          console.log("resulting_json: "+resulting_json.img)
        }

        console.log("temp_all_data: "+temp_all_data);
        setNftListInWallet(temp_all_data)
        setIdsof_StakedNfts(temp_Idsof_stakedNfts);

        
      }
    });
    setisLoading(false);
    if(temp_all_data.length > 0){
      setSeeCollection(true)
    }
  }
  async function fetch_data_before(){
    const web3 = new Web3("https://evm-cronos.crypto.org/");
    const contract = new web3.eth.Contract(staking_contract_abi, staking_contract_address);
    //await Web3.givenProvider.enable()

    contract.methods.totalNftStaked().call((err,result) => {
      console.log("error: "+err);
      if(result != null){
        console.log("Total WOC Staked: "+ result)
        setTotalWOCStaked(result)
      }
  })
  }
  async function stakeNft(listOfIds){
    const contract = new web3Global.eth.Contract(staking_contract_abi, staking_contract_address);
    try{
      setisLoading(true);
      const estemated_Gas = await contract.methods.stakeNfts(listOfIds).estimateGas({
        from : connectedAddress, 
        maxPriorityFeePerGas: null,
        maxFeePerGas: null
      });
      console.log(estemated_Gas)
      const result = await contract.methods.stakeNfts(listOfIds).send({
        from : connectedAddress,
        gas: estemated_Gas,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null
      })
      alert("Staking Success");
      fetch_Data_After_Wallet_Connect(connectedAddress);
      setisLoading(false);
    }catch(e){
      show_error_alert(e);
      setisLoading(false);
    }

  }
  async function unstakeNft(listOfIds){
    const contract = new web3Global.eth.Contract(staking_contract_abi, staking_contract_address);
    try{
      console.log("listOfIds: "+ listOfIds)
      setisLoading(true);
      const estemated_Gas = await contract.methods.unstakeNfts(listOfIds).estimateGas({
        from : connectedAddress, 
        maxPriorityFeePerGas: null,
        maxFeePerGas: null
      });
      console.log(estemated_Gas)
      const result = await contract.methods.unstakeNfts(listOfIds).send({
        from : connectedAddress,
        gas: estemated_Gas,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null
      })
      
      await fetch_Data_After_Wallet_Connect(connectedAddress);
      setisLoading(false);
      alert("Success");
    }catch(e){
      show_error_alert(e);
      setisLoading(false);
    }

  }
  async function claimRewards(){
    const contract = new web3Global.eth.Contract(staking_contract_abi, staking_contract_address);
    try{
      setisLoading(true);
      const estemated_Gas = await contract.methods.claimRewards().estimateGas({
        from : connectedAddress, 
        maxPriorityFeePerGas: null,
        maxFeePerGas: null
      });
      console.log(estemated_Gas)
      const result = await contract.methods.claimRewards().send({
        from : connectedAddress,
        gas: estemated_Gas,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null
      })
      await fetch_Data_After_Wallet_Connect(connectedAddress);
      setisLoading(false);
      alert("Success");
    }catch(e){
      setisLoading(false);
      show_error_alert(e);
    }

  }
  async function approvenfts(){
    const contract = new web3Global.eth.Contract(nft_contract_abi, nft_contract_address);
    try{
      setisLoading(true);
      const estemated_Gas = await contract.methods.setApprovalForAll(staking_contract_address, true).estimateGas({
        from : connectedAddress, 
        maxPriorityFeePerGas: null,
        maxFeePerGas: null
      });
      console.log(estemated_Gas)
      const result = await contract.methods.setApprovalForAll(staking_contract_address, true).send({
        from : connectedAddress,
        gas: estemated_Gas,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null
      })
      setisLoading(false);
      setIsApproved(true);
    }catch(e){
      setisLoading(false);
      show_error_alert(e);
    }

  }
  async function show_error_alert(error){
    let temp_error = error.message.toString();
    console.log(temp_error);
    let error_list = [
      "Not Started Yet!",
      "Contract doesnot have enough reward Tokens.",
      "Approve Nfts First",
      "0 Nfts to Stake",
      "INVALID_ETH",
      "The caller is another contract",
      "You donot have enough Crumbos.",
      "Select atleast 1 Nft to burn",
      "Add Crumbos Id",
      "Presale have not started yet.",
      "Presale Ended.",
      "Invalid Arguments",
      "Approve CRUMBOS Nfts",
      "Sent Amount Not Enough",
      "Approve CRUMBOS Nfts",
      "insufficient funds",
      "Exceeding Per Tx Limit",
      "mint at least one token",
      "incorrect ether amount",
      "Presale Ended.",
      "Sale is Paused.",
      "Approve Phase 2 Nfts",
      "Approve Phase 1 Nfts",
      "Max Supply Reached.",
      "Public sale is not started",
      "Needs to send more eth",
      "Public Sale Not Started Yet!",
      "Exceed max adoption amount",
      "Private Sale Not Started Yet!",
      "Exceed max minting amount"
    ]
  
    for(let i=0;i<error_list.length;i++){
      if(temp_error.includes(error_list[i])){
       // set ("Transcation Failed")
        alert(error_list[i]);
      }
    }
  }  
  return (
    <>
    {isLoading ? (
  <div className={`modal`}tabIndex='-1'>
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header d-block text-center">
          <h1 className="modal-title fs-5" id="exampleModalLabel">Loading</h1>
          {/* <button type="button" onClick={() => setisLoading(!isLoading)} className="btn-close position-absolute end-0 top-0" data-bs-dismiss="modal" aria-label="Close"></button> */}
        </div>
        <div className="modal-body">
        <ReactLoading type="spin" color="#36AD49" height={50} width={50} />
        </div>
      </div>
    </div>
  </div>
) : null }
      {showPopup ? (
        <div className={`modal`}tabIndex='-1'>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header d-block text-center">
                <h1 className="modal-title fs-5" id="exampleModalLabel">Choose the wallet to connect</h1>
                <button type="button" onClick={() => setShowPopup(!showPopup)} className="btn-close position-absolute end-0 top-0" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <button className="btn btn-border" onClick={() => connect_wallet()}>
                  <img src="/images/metamask.svg" alt="Metamask" />
                  <span>Metamask</span>
                </button>
                <button className="btn btn-border" onClick={() => connect_wallet()}>
                  <img src="/images/crypto-wallet.svg" alt="Crypto.com Wallet" />
                  <span>Crypto.com Wallet</span>
                </button>
                <button className="btn btn-border" onClick={() => connect_wallet_WalletConnect()}>
                  <img src="/images/connect-wallet.svg" alt="Connect Wallet" />
                  <span>Wallet Connect</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null }
    <div>
      {/* Navbar Start */}
      <nav className="navbar navbar-expand-lg navbar-light position-sticky sticky-top bg-white">
        <div className="container">
          <a className="navbar-brand d-flex align-items-center" href="/">
            <img
              className="img-fluid logo-brand"
              src={logo}
              alt="World of Cats"
            />
            <span className="logo-name ms-3">World of Cats</span>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              {navLinks.map(({ link, name, dropDown, dropDownValues }) =>
                dropDown ? (
                  <li className="nav-item dropdown" key={name}>
                    <a
                      className="nav-link dropdown-toggle"
                      href={link}
                      id={`navbarDropdown-${name}`}
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {name}
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby={`navbarDropdown-${name}`}
                    >
                      {dropDownValues.map(({ dropDownName, dropDownLink }) => (
                        <li key={dropDownName}>
                          <a className="dropdown-item" href={`https://www.worldofcats.xyz${dropDownLink}`}>
                            {dropDownName}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </li>
                ) : (
                  <li className="nav-item" key={name}>
                    <a className="nav-link" href={`https://www.worldofcats.xyz${link}`}>
                      {name}
                    </a>
                  </li>
                )
              )}
              <li className="nav-btn">
                <a href="https://discord.gg/worldofcats" target="_blank" rel="noreferrer">
                  <img src="/images/discord.svg" alt="Discord" />
                </a>
              </li>
              <li className="nav-btn">
                <a href="https://twitter.com/WorldOfCatsNFT" target="_blank" rel="noreferrer">
                  <img src="/images/twitter.svg" alt="Twitter" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* Navbar End */}

      <div className="header">
        <div className="container pt-5">
          <div className="row">
            <div className="col-12">
              <h2 className="text-center">
                Staking <br /> in FELINE FARM
              </h2>
            </div>
          </div>
        </div>
      </div>
  
      {stateOne ? (
        <section id="stateOne">
          <div className="stateOne">
            <div className="container py-5">
              <div className="row">
                <div className="col-12 text-center">
                  <div className="totals mb-4">
                    <ul className="list-unstyled">
                      <li>
                        <span>Total WOC NFTStaked</span>
                        <strong>{totalWOCStaked}</strong>
                      </li>
                      <li>
                        <span>% WOC NFT Staked</span>
                        <strong>{((100 * totalWOCStaked) / 4444).toFixed(2)} %</strong>
                      </li>
                    </ul>
                  </div>

                  <div className="stateCta mb-4">
                    <button
                      className="btn"
                      onClick={() => {
                      //  setStateOne(!stateOne)
                     setShowPopup(true);
                      }}
                    >
                      Connect your wallet
                    </button>
                    
                  </div>

                  <div className="text">
                    <p>Don't let your NFT WOC sleep. </p>
                    <p>
                      By staking your WOC NFT, you generate $FELINE every day.
                    </p>
                    <p> Stop staking whenever you want. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section id="stateTwo">
          <div className="stateTwo">
            <div className="container py-5 px-lg-5">
              <div className="row">
                <div className="col-12 col-md-4">
                  <h3>My wallet</h3>
                  <ul className="list-unstyled border-bottom border-dark border-2">
                    <li>
                      <span>Wallet ID</span>
                      <strong>{shortenAddress}</strong>
                    </li>
                    <li>
                      <span>$FEL</span>
                      <strong>
                        <img src="/images/coin.svg" alt="coin" /> {tokensEarned}
                      </strong>
                    </li>
                  </ul>
                  <ul className="list-unstyled">
                    <li>
                      <span>WOC NFT Staked</span>
                      <strong>{totalStakedNftsByUser}</strong>
                    </li>
                    <li>
                      <span>WOC NFT Unstake</span>
                      <strong>{total_UnStakedNftsByUser}</strong>
                    </li>
                    <li>
                      <span>Rewards per day</span>
                      <strong>{rewardsPerDay} $FEL/day</strong>
                    </li>
                  </ul>

                  {seeCollection ? (
                    <div className="d-flex justify-content-between flex-wrap mt-4">
                      {isApproved ? (
                        <button className="btn btn-black w-100 mb-4 btn-lg" onClick={claimRewards}>
                          Claim your <img src="/images/coin.svg" alt="Coins" />
                          <span className="coin-number">{tokensEarned}</span>
                        </button>
                      ) : (
                        <button className="btn btn-black w-100 mb-4 btn-lg" onClick={approvenfts}>
                          Approve Nfts
                        </button>                        
                      )}

                      <button className="btn btn-success btn-lg" onClick={() => {
                        stakeNft(IdsofUnstakedNfts)
                      }}>
                        Stake All
                      </button>
                      <button className="btn btn-light btn-lg" onClick={() => {
                        unstakeNft(Idsof_StakedNfts);
                      }}>
                        Unstake All
                      </button>
                    </div>
                  ) : null}

                </div>
                <div className="col-12 col-md-8">
                  <div className="bg-gray">
                    {!seeCollection ? (
                      <div className="text text-center">
                        <p>You don't have an NFT yet. </p>
                        <p>Visit our collection to find your favorite cats. </p>
                        <div className="stateCta mb-4 mt-5"  href="https://app.ebisusbay.com/collection/world-of-cats">
                        <a target="_blank" href="https://app.ebisusbay.com/collection/world-of-cats">
                          <button
                            className="btn"
                           href="https://app.ebisusbay.com/collection/world-of-cats"
                          >
                            <img
                              src="/images/view.svg"
                              alt="view"
                              className="me-3"
                            />
                            See our collection
                          </button>
                          </a>
                        </div>
                      </div>
                    ) : (
                      <div className="images row g-5">
                        {nftListinWallet.map(({ img, number, staked, price }) => (
                          <div key={number} className="item col-6 col-lg-4">
                            <div className="inner h-100 bg-white d-flex flex-column">
                              <div className="img d-flex flex-column">
                                <img src={img} alt={number} className="img-size"/>
                                <strong>#{number}</strong>
                              </div>
                              {!staked ? (
                                <div className="unstake d-flex flex-column">
                                  <p>
                                    <img src="/images/coin.svg" alt="coin" />
                                    <strong className="ms-2">{price}</strong>
                                  </p>
                                  <button className="btn btn-success mt-auto" onClick={() => {
                                    let x = parseInt(number);
                                    stakeNft([x]); 
                                  }}>
                                    Stake
                                  </button>
                                </div>
                              ) : (
                                <button className="btn btn-light" onClick={() => {
                                  let x = parseInt(number);
                                  unstakeNft([x]); 
                                }}>
                                  Unstake
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>



</>
  );
};

export default Main;
