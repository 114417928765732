import "./App.css";
import React from "react";
import Main from "./Main";


const App = () => {
  return (

      <Main />

  );
};

export default App;
