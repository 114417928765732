const navLinks = [
  {
    link: '#',
    name: 'The Project',
    dropDown: true,
    dropDownValues: [
      {
        dropDownName: 'Charity Program',
        dropDownLink: '/charity'
      },
      {
        dropDownName: 'Storytelling',
        dropDownLink: '/storytelling'
      },
      {
        dropDownName: 'Branding',
        dropDownLink: '/branding'
      },
      {
        dropDownName: 'Utilities',
        dropDownLink: '/utilities'
      },
      {
        dropDownName: 'Roadmap',
        dropDownLink: '/roadmap'
      },
      {
        dropDownName: 'Whitepaper',
        dropDownLink: '/whitepaper'
      },
    ]
  },
  {
    link: '#',
    name: 'NFT',
    dropDown: true,
    dropDownValues: [
      {
        dropDownName: 'Mint Page',
        dropDownLink: '/mint'
      },
      {
        dropDownName: 'Holder Benefits',
        dropDownLink: '/benefits'
      },
    ]
  },
  {
    link: '#',
    name: 'Fun',
    dropDown: true,
    dropDownValues: [
      {
        dropDownName: 'Game Section',
        dropDownLink: '/game-section'
      },
      {
        dropDownName: 'Meme Generator',
        dropDownLink: '/meme-generator'
      },
    ]
  },
  {
    link: '/shop',
    name: 'Shop',
    dropDown: false
  },
  {
    link: '/faq',
    name: 'FAQ',
    dropDown: false
  },
];

export default navLinks;